<template>
  <v-app>
    <transition name="fade-in-up">
      <div class="row">
        <div class="col-md-12">
          <ExpensesList
            :headers="headersExpenses"
            :items="itemsExpenses"
            :data_table_top="true"
            :data_table_footer="true"
            :search_tool="true"
            @initialize="initialize"
          ></ExpensesList>
        </div>
      </div>
    </transition>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import ExpensesList from "@/views/pages/expenses/ExpensesList.vue";
import genericService from "./../../../services/generic.service";
import { mapGetters } from "vuex";

export default {
  components: {
    ExpensesList,
  },

  data: () => ({
    uri: "expenses",
    headersExpenses: [
      { text: "ID", value: "id", filterable: true },
      { text: "Work Order", value: "work_order", filterable: true },
      { text: "Vessel", value: "vessel", filterable: true },
      { text: "Invoice Number", value: "invoice_number", filterable: true },
      { text: "Company Name", value: "company_label", filterable: true },
      { text: "Date", value: "date" },
      { text: "Surveyor Att.", value: "surveyor_attendance", filterable: true },
      { text: "Paid", value: "paid" },
      { text: "Paid Date", value: "paid_date" },
      { text: "Total", value: "total", filterable: true },
      { text: "Actions", value: "actions" },
    ],
    itemsExpenses: [],
  }),

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      var _self = this;
      
      genericService.getRecordListWithLimit(
        "/" + this.uri,
        10,
        "date",
        _self.currentUser.role,
        _self.currentUser.id,
        function (records) {
          _self.itemsExpenses = records;
        }
      );
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Expenses" }]);
  },

  computed: {
    ...mapGetters(["currentUser"]),
  },
};
</script>
