<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :search="search"
    :single-expand="singleExpand"
    :expanded.sync="expanded"
    show-expand
    item-key="id"
    sort-by="date"
    sort-desc
    class="elevation-1"
    @current-items="currentItems"
    :loading="loading"
    loading-text="Loading... Please wait"
  >
    <template v-if="data_table_top" v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Expenses List</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-text-field
          v-if="search_tool"
          v-model="search"
          ref="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
          clearable
        ></v-text-field>

        <!-- BOM Filtros -->
        <v-dialog v-if="search_tool" v-model="dialogFilters" max-width="600px">
          <template v-slot:activator="{ on }">
            <v-btn
              class="ml-4"
              color="blue-grey"
              fab
              small
              dark
              v-on="on"
              @click="dialogFilters = true"
            >
              <v-icon>mdi-filter</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Advanced Search</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="6" sm="6">
                    <v-menu
                      ref="rangeCalendar"
                      v-model="rangeCalendar"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-x
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="dateRangeText"
                          label="Dates"
                          hint="Enter a date range range. Separate with '~'"
                          persistent-hint
                          prepend-icon="event"
                          v-on="on"
                          clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="rangeDates" no-title range>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="rangeDates = []"
                          >Clear</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.rangeCalendar.save(rangeDates)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6" sm="6">
                    <v-text-field
                      v-model="filterWorkOrder"
                      label="Work Order"
                      single-line
                      hide-details
                      clearable
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="clearFilterItems()"
                >Clear Filters</v-btn
              >
              <v-btn color="blue darken-1" text @click="dialogFilters = false"
                >Close</v-btn
              >
              <v-btn color="blue darken-1" text @click="filterItems()"
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- EOM Filtros -->

        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="750px">
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-on="on"
              @click="formNewItem()"
              >New Expense</v-btn
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row v-if="formNewtItem" class="form-first-field">
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                      prepend-icon="folder"
                      placeholder="Select Work Order"
                      :items="workOrdersList"
                      item-text="id"
                      item-value="id"
                      label="Work Order"
                      v-model="current_wo_id"
                      @change="getWOData(current_wo_id)"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      prepend-icon="folder"
                      v-model="current_wo_vessel"
                      label="Vessel"
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <!--<v-autocomplete
                      prepend-icon="business"
                      placeholder="Select Company"
                      :search-input.sync="editedItem.company_label"
                      :items="companyList"
                      item-text="name"
                      item-value="id"
                      label="To"
                      v-model="editedItem.company_name"
                    ></v-autocomplete>-->
                    <v-text-field
                      v-model="editedItem.company_label"
                      label="Company Name"
                      prepend-icon="business"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <!--Levanta los surveyor_attendance segun el WO elegido previamente. Hay algunos WO que no tienen surveyor_attendance. En ese caso desactivamos el campo.-->
                    <v-autocomplete
                      prepend-icon="article"
                      placeholder="Surveyor Attendance Nr."
                      :items="woAttendanceList"
                      item-text="id"
                      item-value="id"
                      label="Surveyor Attendance Nr."
                      v-model="editedItem.surveyor_attendance"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-menu
                      v-model="popDatepick1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="editedItem.date"
                          label="Date"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedItem.date"
                        @input="popDatepick1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.total"
                      type="number"
                      prefix="$"
                      label="Total"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-switch
                      v-model="editedItem.paid"
                      color="info"
                      hide-details
                    >
                      <template v-slot:label>
                        Paid:
                        <v-chip
                          color="green"
                          dark
                          v-if="editedItem.paid == 1"
                          class="ml-2"
                          >Yes</v-chip
                        >
                        <v-chip color="red" dark v-else class="ml-2">No</v-chip>
                      </template>
                    </v-switch>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-menu
                      v-model="popDatepick2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="editedItem.paid_date"
                          label="Paid Date"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedItem.paid_date"
                        @input="popDatepick2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.invoice_number"
                      type="number"
                      label="Invoice Number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      v-model="editedItem.description"
                      label="Description"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-file-input
                      v-model="editedItem.attachment"
                      type="file"
                      color="primary"
                      counter
                      label="Attachment"
                      multiple
                      placeholder="Select your files"
                      prepend-icon="mdi-paperclip"
                      outlined
                      :show-size="1000"
                    >
                      <template v-slot:selection="{ index, text }">
                        <v-chip
                          v-if="index < 2"
                          color="primary"
                          dark
                          label
                          small
                          >{{ text }}</v-chip
                        >

                        <span
                          v-else-if="index === 2"
                          class="overline grey--text text--darken-3 mx-2"
                          >+{{ files.length - 2 }} File(s)</span
                        >
                      </template>
                    </v-file-input>
                  </v-col>
                  <v-row v-if="loadesFiles.length > 0">
                    <v-container fluid>
                      <h5>Current Attachments:</h5>
                      <li
                        v-for="attachment in loadesFiles"
                        :key="attachment.id"
                      >
                        <v-btn class="ma-2" color="primary" dark>
                          {{ attachment.name }}
                          <v-icon
                            small
                            @click="downloadFile(attachment.id)"
                            title="Delete"
                            >mdi-download</v-icon
                          >
                        </v-btn>
                        <v-icon
                          small
                          @click="deleteAttachment(item, attachment.id, index)"
                          title="Delete"
                          >mdi-delete</v-icon
                        >
                      </li>
                    </v-container>
                  </v-row>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save(items)"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.paid="{ item }">
      <v-chip color="green" dark v-if="item.paid == 1">yes</v-chip>
      <v-chip color="red" dark v-else>no</v-chip>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item, 'dialog')"
        v-b-tooltip.hover
        title="Edit"
        >mdi-pencil</v-icon
      >
      <v-icon
        small
        @click="deleteItem(item, items)"
        v-b-tooltip.hover
        title="Delete"
        >mdi-delete</v-icon
      >
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="reset">Reset</v-btn>
    </template>
    <template v-slot:expanded-item="{ item }">
      <td :colspan="headers.length + 1">
        <div class="row justify-center">
          <div class="col-md-11">
            <div class="row mt-3">
              <div class="col-5">
                <v-container fluid>
                  <h5>Description:</h5>
                  <p>
                    {{ item.description }}
                  </p>
                </v-container>
              </div>
              <div class="col-6">
                <v-container fluid>
                  <h5>Attachments:</h5>
                  <li
                    v-for="(attachment, index) in item.attachments_list"
                    :key="attachment.id"
                  >
                    <v-btn
                      class="ma-2"
                      color="primary"
                      dark
                      @click="downloadFile(attachment.id)"
                    >
                      {{ attachment.name }}
                      <v-icon
                        small
                        @click="downloadFile(attachment.id)"
                        title="Delete"
                        >mdi-download</v-icon
                      >
                    </v-btn>
                    <v-icon
                      small
                      @click="deleteAttachment(item, attachment.id, index)"
                      title="Delete"
                      >mdi-delete</v-icon
                    >
                  </li>
                </v-container>
              </div>
            </div>
          </div>
        </div>
      </td>
    </template>
    <template v-slot:item.total="{ item }">{{
      formatAmount(item.total)
    }}</template>
    <template v-if="data_table_footer" slot="footer">
      <table>
        <tr
          class="border border-right-0 border-left-0 border-bottom-0 text-center"
        >
          <td colspan>
            <v-chip color="lighten-1 mr-4">
              Items: &nbsp;
              <strong class="ml-2">{{ itemsFiltered }}</strong>
            </v-chip>
            <v-chip color="lighten-1">
              Amount: &nbsp;
              <strong class="ml-2">{{ totalAmount }}</strong>
            </v-chip>
          </td>
        </tr>
      </table>
    </template>
  </v-data-table>
</template>

<script>
import genericService from "./../../../services/generic.service";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
  data: () => ({
    uri: "expenses",
    workOrdersList: [],
    woAttendanceList: [],
    today: new Date().toISOString().substr(0, 10),
    loadesFiles: [],
    formNewtItem: true,
    dialog: false,
    search: "",
    expanded: [],
    singleExpand: false,
    popDatepick1: false,
    popDatepick2: false,
    radios: "",
    totalAmount: 0,
    editedIndex: -1,
    currentId: "",
    editedItem: {
      id: "",
      work_order: null,
      company_name: "",
      company_label: "",
      surveyor_attendance: "",
      paid_date: null,
      date: new Date().toISOString().substr(0, 10),
      total: null,
      paid: true,
      description: "",
      invoice_number: null,
      vessel: "",
      attachment: [],
      attachments_list: [],
    },
    defaultItem: {
      id: "",
      work_order: null,
      company_name: "",
      company_label: "",
      surveyor_attendance: "",
      paid_date: null,
      date: new Date().toISOString().substr(0, 10),
      total: null,
      paid: true,
      description: "",
      invoice_number: null,
      vessel: "",
      attachment: [],
      attachments_list: [],
    },
    switch1: true,

    /* Filtros & Loading */
    loading: true,
    filters: [
      { text: "All", value: 99 },
      { text: "Work Order", value: 0 },
      { text: "Vessel", value: 1 },
      { text: "Company Mail", value: 2 },
      { text: "total", value: 5 },
    ],
    date1: "",
    date2: "",
    formatedDates: "",
    rangeDates: [],
    rangeCalendar: false,
    dialogFilters: false,
    itemsFiltered: 0,
    filterWorkOrder: "",
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Expense" : "Edit Expense";
    },
    /* Filters */
    dateRangeText() {
      return this.formatDate(this.rangeDates);
    },
    ...mapGetters(["currentUser"]),
  },

  props: [
    "headers",
    "items",
    "data_table_top",
    "data_table_footer",
    "search_tool",
    "current_wo_id",
    "current_wo_vessel",
  ],

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    var _self = this;

    _self.currentId = _self.$route.params.expenseid;
    if (_self.currentId != undefined && _self.currentId != "") {
      genericService.getRecord(_self.uri, _self.currentId, function (record) {
        _self.currentItem = record;
        _self.editItem(_self.currentItem, "dialog");
      });
    }
  },

  methods: {
    getWOData(woId) {
      var _self = this;

      genericService.getRelatedWORecordList(
        "/workorders",
        woId,
        "surveyorattlist",
        _self.currentUser.role,
        _self.currentUser.id,
        function (records) {
          console.log(records);
          _self.woAttendanceList = records;
        }
      );

      genericService.getRecord("workorders", woId, function (record) {
        _self.current_wo_vessel = record.vessel;
      });
    },

    getLoadesFiles(item) {
      var _self = this;

      genericService.getRelatedFiles(_self.uri, item.id, function (records) {
        _self.loadesFiles = records.attachments_list;
      });
    },

    editItem(item, dialog) {
      this.getWOData(item.work_order);
      this.getLoadesFiles(item);

      /* Listado de Dialogs */
      if (dialog == "dialog") {
        this.formNewtItem = false;
        this.dialog = true;
      }
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },

    deleteItem(item, list) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        const index = list.indexOf(item);
        if (result.value) {
          genericService.deleteItem(this.uri, item.id);
          list.splice(index, 1);
          Swal.fire({
            title: "Deleted!",
            text: "Your record has been deleted.",
            icon: "success",
          });
        }
      });
    },

    deleteAttachment(item, id, index) {
      if (confirm("Are you sure you want to delete this attachment?")) {
        genericService.deleteItem("files", id);
        item.attachments_list.splice(index, 1);
        Swal.fire({
          title: "",
          text: "Record has been successfully deleted!",
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
        });
      }
    },

    downloadFile(fileId) {
      genericService.getFile(fileId, function (fileContent) {
        var a = document.createElement("a");
        a.href = fileContent.data;
        a.download = fileContent.name;
        a.click();
      });
    },

    close() {
      /* Listado de Dialogs */
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save(items) {
      var _self = this;

      var tmpFiles = _self.editedItem.attachment;
      //_self.editedItem.attachment = "";
      //_self.editedItem.attachments_list = "";

      if (this.editedIndex > -1) {
        Object.assign(items[this.editedIndex], this.editedItem);
        genericService.updateRecord(
          "/" + this.uri + "/" + this.editedItem.id,
          this.editedItem,
          function () {
            Swal.fire({
              title: "",
              text: "Record has been successfully saved!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });

            tmpFiles.forEach((attach) => {
              var fileExtension = attach.type.split("/")[1];
              if (fileExtension == "plain") {
                fileExtension = "txt";
              }

              var reader = new FileReader();

              reader.onload = function () {
                var fileObject = {
                  fileName: attach.name,
                  fileType: attach.type,
                  fileExtension: fileExtension,
                  fileContent: reader.result,
                };

                genericService.createRecord(
                  "/" + _self.uri + "/" + _self.editedItem.id + "/files",
                  fileObject,
                  function (rta) {
                    _self.updateAttachment(rta, _self.editedItem);
                  }
                );
              };

              reader.readAsDataURL(attach);
            });
          }
        );
      } else {
        this.editedItem.work_order = this.current_wo_id;
        this.editedItem.vessel = this.current_wo_vessel;
        items.push(this.editedItem);
        genericService.createRecord(
          "/" + this.uri,
          this.editedItem,
          function (rta) {
            if (rta != undefined) {
              Swal.fire({
                title: "",
                text: "Record has been successfully saved!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
              });
              _self.editedItem.id = rta.id;

              tmpFiles.forEach((attach) => {
                var fileExtension = attach.type.split("/")[1];
                if (fileExtension == "plain") {
                  fileExtension = "txt";
                }

                var reader = new FileReader();

                reader.onload = function () {
                  var fileObject = {
                    fileName: attach.name,
                    fileType: attach.type,
                    fileExtension: fileExtension,
                    fileContent: reader.result,
                  };

                  genericService.createRecord(
                    "/" + _self.uri + "/" + rta.id + "/files",
                    fileObject,
                    function (rta) {
                      _self.updateAttachment(rta, _self.editedItem);
                    }
                  );
                };

                reader.readAsDataURL(attach);
              });
            }
          }
        );
      }
      this.currentItems(items);
      this.close();
    },

    updateAttachment(rta, item) {
      if (rta != undefined) {
        if (location.hash.includes("expenses")) {
          //go to expenses edited item modal
          this.$router.push({
            name: "expense-detail",
            params: { expenseid: item.id },
          });
          location.href = "#/expenses/" + item.id;
          location.reload();
        } else {
          //go to WO detail tab
          this.$router.push({
            name: "work-order-detail-tab",
            params: { id: this.current_wo_id, tab: 5 },
          });
          location.href = "#/work-orders/" + this.current_wo_id + "/7";
          location.reload();
        }
      }
    },

    reset() {
      this.$emit("initialize");
    },

    toNumber(nr) {
      nr = parseFloat(nr);
      return nr;
    },

    formatCurrencyAmount(amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      });

      return formatter.format(amount);
    },

    formatAmount(nr) {
      if (nr == undefined || nr == null || isNaN(nr)) {
        return 0;
      }
      return nr;
    },

    currentItems(items) {
      this.loading = true;
      setTimeout(() => {
        this.itemsFiltered = items.length;

        var sum = items.reduce(
          (a, item) => a + this.formatAmount(parseFloat(item.total)),
          0
        );
        this.totalAmount = this.formatCurrencyAmount(sum);

        this.loading = false;
      }, 300);
    },

    /* BOM - Filtros */
    clearFilterItems() {
      var _self = this;
      _self.rangeDates = [];
      _self.dateRangeText = "";
      _self.filterWorkOrder = "";

      genericService.getRecordListWithLimit(
        "/" + this.uri,
        10,
        "date",
        _self.currentUser.role,
        _self.currentUser.id,
        function (records) {
          _self.items = records;
          _self.dialogFilters = false;
        }
      );
    },

    filterItems() {
      var _self = this;
      var params = {
        module: _self.uri,
        range: _self.rangeDates,
        wo: _self.filterWorkOrder,
      };

      genericService.getRecordListWithParams(params, _self.currentUser.role, _self.currentUser.id, function (records) {
        _self.items = records;
        _self.dialogFilters = false;
      });
    },

    formatDate(dates) {
      if (dates[1]) {
        const [year1, month1, day1] = dates[0].split("-");
        const [year2, month2, day2] = dates[1].split("-");
        this.date1 = `${day1}/${month1}/${year1}`;
        this.date2 = `${day2}/${month2}/${year2}`;

        this.formatedDates = this.date1 + " ~ " + this.date2;
      } else {
        this.formatedDates = "";
      }
      return this.formatedDates;
    },

    focusField(target) {
      setTimeout(() => {
        target.focus();
      }, 500);
    },
    /* EOM - Filtros */

    formNewItem() {
      this.loadesFiles = [];
      this.editedItem.attachment = [];
      this.editedItem.attachments_list = [];

      this.formNewtItem = true;
      setTimeout(() => {
        this.focusField(this.$refs.formFirstField);
      }, 200);
    },
  },
  mounted() {
    var _self = this;

    genericService.getRecordList("/workorders/", function (records) {
      _self.workOrdersList = records.reverse();
    });

    if (this.search_tool == true) {
      this.focusField(this.$refs.search);
    }

    _self.getWOData(this.current_wo_id);
  },
};
</script>
